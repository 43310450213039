import request from "@/utils/request";

/**
 * 修改头像和昵称
 * @param {*} data 
 * @param {String} nickName || 昵称
 * @param {String} avatar || 头像
 * @returns 
 */
export const updateInfo = (data) => {
  return request({
    url: "/api/app/account/updateInfo",
    method: "post",
    data: data,
  });
};

/**
 * 设置密码
 * @param {String} newPassword || 新密码
 * @param {String} verificationCode || 验证码
 */
export const initialPassword = (data) => {
  return request({
    url: "/api/app/account/initialPassword",
    method: "post",
    data: data,
  });
};

/**
 * 修改密码
 * @param {String} oldPassword || 旧密码
 * @param {String} newPassword || 新密码
 */
export const updatePassword = (data) => {
  return request({
    url: "/api/app/account/updatePassword",
    method: "post",
    data: data,
  });
};
