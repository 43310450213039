<template>
    <div class="pages">
        <pageHeader></pageHeader>
        <div class="box">
            <div class="main">
                <acountNav></acountNav>
                <div class="acount-box">
                    <div class="acount-header">
                        <div class="acount-header-item" :class="index === headerIdx ? 'acount-header-item-active' : ''"
                            v-for="(item, index) in headerLists" :key="index
                                " @click="tapHeaderNav(index)">{{ item }}</div>
                    </div>
                    <div class="acount-main">
                        <div class="userinfo" v-if="headerIdx === 0">
                            <!-- <el-upload :action="action" class="upload-demo" ref="upload" :auto-upload="false"
                                @on-change="chooseImg" :limit="1" :show-file-list="false">
                                
                            </el-upload> -->
                            <el-upload class="el-upload-box" :action="action" list-type="picture-card" :on-success="handleUploadSuccess"
                                :limit="1" name="file" :show-file-list="false" :headers="headers" :data="uploadData" :before-upload="handleBeforeUpload">
                                <img class="touxiang" v-if="userInfo.avatar" :src="userInfo.avatar">
                                <img class="touxiang" v-else src="../../static/icon/avatar.png">
                            </el-upload>
                            <input class="input-box1" v-model="userInfo.nickName" type="text" placeholder="请输入用户名">
                            <div class="submit-btn" @click="updataInfo">保存</div>
                        </div>
                        <div class="account-security" v-if="headerIdx === 1">
                            <!-- <span class="account-security-title">更换手机号</span>
                            <div class="input-box">
                                <span class="phone-region">+86</span>
                                <input v-model="userInfo.userName" type="text" placeholder="请输入原手机号">
                            </div>
                            <div class="input-box">
                                <span class="phone-region">+86</span>
                                <input v-model="phoneNumber" type="text" placeholder="请输入新手机号">
                            </div>
                             -->
                            <span class="account-security-title">{{ userInfo.pwdInit ? '修改密码' : '设置密码' }}</span>
                            <div class="input-box" v-if="userInfo.pwdInit">
                                <input v-model="oldPassword" :type="isShowpsw1?'text':'password'" placeholder="请输入原密码">
                                <img @click="isShowpsw1 = !isShowpsw1" src="../../static/icon/yan-icon.png">
                            </div>
                            <div class="input-box">
                                <input v-model="newPassword" :type="isShowpsw2?'text':'password'" placeholder="请输入新密码">
                                <img @click="isShowpsw2 = !isShowpsw2" src="../../static/icon/yan-icon.png">
                            </div>
                            <div class="input-box">
                                <input v-model="newPassword1" :type="isShowpsw3?'text':'password'" placeholder="请确认新密码">
                                <img @click="isShowpsw3 = !isShowpsw3" src="../../static/icon/yan-icon.png">
                            </div>
                            <div class="input-box" v-if="!userInfo.pwdInit">
                                <span class="phone-region">+86</span>
                                <input v-model="userInfo.phoneNumberMask" disabled type="text">
                            </div>
                            <div class="input-box" v-if="!userInfo.pwdInit">
                                <input v-model="code" type="text" placeholder="请输入验证码">
                                <span class="get-code" @click="getCode">{{ smsCodeTrue ? '获取验证码' : smsCodeTime + '秒后再次获取'
                                }}</span>
                            </div>
                            <div class="btn" @click="submit">保存</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <pageFooter></pageFooter>
    </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import pageHeader from '@/components/pageHeader.vue'
import pageFooter from '@/components/pageFooter.vue'
import acountNav from '@/components/acountNav.vue'
import { getUserInfo, smsCode } from '@/api/login.js'
import { updateInfo, initialPassword, updatePassword } from '@/api/userInfo.js'
import { getOssConfig } from '@/api/oss.js'
// import { validatePhoneNumber } from '@/utils/utils.js'
// import OSS from 'ali-oss';
export default {
    name: "college",
    components: {
        pageHeader,
        pageFooter,
        acountNav
    },
    data() {
        return {
            headerLists: ['基本资料', '账号与安全'],
            headerIdx: 0,
            userInfo: {},
            smsCodeTrue: true,//是否可以发送验证码
            smsCodeTime: null,//验证码倒计时
            code: '',//验证码
            phoneNumber: '',//手机号
            oldPassword: '',//原密码
            newPassword: '',//新密码
            newPassword1: '',//新确认密码
            client: null,
            action: '',//上传地址
            headers: {
                Authorization: "Bearer " + getToken(),
            },
            uploadData:{},
            isShowpsw1:false,
            isShowpsw2:false,
            isShowpsw3:false,
        }
    },
    created() {
        this.getUserInfo()
    },
    methods: {
        async handleBeforeUpload(file) {
            let extensions = JSON.stringify([file.type.split('/')[1]])
            await getOssConfig({
                extensions,
            }).then(res => {
                this.action = res[0].host
                this.uploadData.OSSAccessKeyId = res[0].accessKeyId
                this.uploadData.success_action_status = 200
                this.uploadData.policy = res[0].policy
                this.uploadData.signature = res[0].signature
                this.uploadData.key = res[0].dir
            })
        },
        // 上传成功回调
        handleUploadSuccess(res) {
            console.log(res)
            this.userInfo.avatar = this.action + '/' + this.uploadData.key
        },
        /**
         * 获取验证码
         */
        getCode() {
            if (!this.smsCodeTrue) {
                this.$message.error('请' + this.smsCodeTime + '秒后再次发送');
                return
            }
            smsCode({
                phone: this.userInfo.userName
            }).then(() => {
                this.$message.success('发送验证码成功')
                this.smsCodeTrue = false
                this.smsCodeTime = 60
                let timeDown = setInterval(() => {
                    if (this.smsCodeTime !== 0) {
                        this.smsCodeTime--
                    } else {
                        clearInterval(timeDown)
                        this.smsCodeTrue = true
                        this.smsCodeTime = null
                    }
                }, 1000);
            })
        },
        /**
         * 获取个人信息
         */
        getUserInfo() {
            getUserInfo().then(userInfo => {
                this.userInfo = userInfo
            })
        },
        /**
         * 切换nav
         */
        tapHeaderNav(index) {
            this.headerIdx = index
        },
        /**
         * 修改个人信息
         */
        updataInfo() {
            updateInfo({
                nickName: this.userInfo.nickName,
                avatar: this.userInfo.avatar
            }).then(() => {
                this.$message.success('信息修改成功');
                this.$router.push({ path: "/refresh" });
            })
        },
        /**
         * 设置修改密码
         */
        submit() {
            if (!this.newPassword) {
                this.$message.error('请输入新密码');
                return
            }
            if (this.newPassword !== this.newPassword1) {
                this.$message.error('两次密码输入不一致');
                return
            }
            if (!this.userInfo.pwdInit) {
                if (!this.code) {
                    this.$message.error('请输入验证码');
                    return
                }
                initialPassword({
                    newPassword: this.newPassword,
                    verificationCode: this.code
                }).then(res => {
                    if (res) {
                        this.$message.success('密码设置成功');
                        this.getUserInfo()
                    }
                })
            } else {
                if (!this.oldPassword) {
                    this.$message.error('请输入原密码');
                    return
                }
                updatePassword({
                    oldPassword: this.oldPassword,
                    newPassword: this.newPassword
                }).then(res => {
                    if (res) {
                        this.$message.success('密码修改成功');
                        this.getUserInfo()
                    }
                })
            }
        }
    }
}
</script>

<style scoped>
.pages {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 120px);
    background-color: #F7F7F8;
}

.main {
    width: 1200px;
    min-height: calc(100vh - 120px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.acount-box {
    width: 888px;
    background-color: #ffffff;
    min-height: calc(100vh - 120px - 16px - 28px);
    margin-top: 16px;
    margin-bottom: 28px;
}

.acount-header {
    width: 888px;
    border-bottom: 1px solid #E8E8E8;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 18px;
}

.acount-header-item {
    height: 60px;
    margin-right: 70px;
    color: #000000;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.acount-header-item-active {
    position: relative;
    color: #DD0722;
}

.acount-header-item-active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #DD0722;
}

.acount-main {
    width: 888px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.acount-main .userinfo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.acount-main .userinfo .touxiang {
    width: 144px;
    height: 144px;
    border-radius: 144px;
}

.acount-main .userinfo .input-box1 {
    margin-top: 40px;
    width: 330px;
    height: 40px;
    border-radius: 28px;
    background-color: #F0F2F5;
    text-align: center;
    color: #999999;
    font-size: 16px;
    border: 0;
}


.acount-main .userinfo .submit-btn {
    margin-top: 80px;
    width: 218px;
    height: 44px;
    background: #DD0722;
    border-radius: 22px;
    font-size: 16px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.account-security {
    width: 888px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.account-security-title {
    color: #06121E;
    font-size: 16px;
    font-weight: bold;
    margin-top: 28px;
    margin-left: 18px;
    margin-bottom: 8px;
}

.input-box {
    width: 388px;
    height: 48px;
    border-radius: 50px;
    background-color: #F0F2F5;
    display: flex;
    align-items: center;
    margin-left: 40px;
    margin-top: 16px;
    padding: 0 18px;
}

.input-box input {
    flex: 1;
    height: 48px;
    border: 0;
    background-color: #F0F2F5;
    color: #999999;
    font-size: 16px;
}

.input-box span {
    cursor: pointer;
}

.input-box img {
    cursor: pointer;
    width: 16px;
    height: 12px;
}

.input-box .phone-region {
    padding-right: 14px;
    border-right: 1px solid #020C17;
    height: 22px;
    margin-right: 12px;
}

.input-box .get-code {
    color: #4181B8;
    font-size: 13px;
}

.account-security .btn {
    width: 218px;
    height: 44px;
    background: #DD0722;
    border-radius: 22px;
    color: #ffffff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 40px;
    margin-top: 72px;
    cursor: pointer;
}

.el-upload-box {
    margin-top: 52px;
    width: 150px;
    height: 150px;
    background-color: none !important;
    border: 1px dashed #ffffff !important;
}
</style>
